<template>
  <div class="lookBox">
    <div class="lookTop">
      <div class="topBox">
        <div @click="showPopup"></div>
        <div>看看</div>
      </div>
      <div class="searchBox">
        <div class="searchDiv">
          <img src="../../image/searchImg.png" alt />
          <input
            type="text"
            @focus="onFocusr()"
            placeholder="请输入您要搜索的内容~"
          />
        </div>
      </div>
      <div class="tabBox">
        <div class="tabDiv">
          <van-tabs
            v-model="tabActive"
            swipeable
            animated
            @click="tabEvent"
            @change="onTabChange()"
          >
            <van-tab
              v-for="item in tabList"
              :title="item.value"
              :key="item.id"
              id="tabStyle"
            >
              <div class="bannerBox" v-if="bannerList.length != 0">
                <van-swipe
                  class="my-swipe"
                  :autoplay="3000"
                  indicator-color="white"
                >
                  <van-swipe-item v-for="item in bannerList" :key="item.id">
                    <img class="bannerImg" :src="item.imgUrl" alt />
                    <div class="bannerTitle">
                      <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.name }}</div>
                      <img
                        src="../../image/j.png"
                        alt=""
                        @click="banEvent(item)"
                      />
                    </div>
                  </van-swipe-item>
                </van-swipe>
              </div>
              <van-loading
                id="loading"
                type="spinner"
                size="24px"
                v-if="loading === true"
              />
              <div class="noticeBox" v-if="messageLoading === false">
                <div class="noticeRight">
                  <img src="../../image/tz.png" alt="" />
                </div>
                <div class="noticeLeft">
                  <div class="xxTitle"  style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ message.title }}</div>
                  <div class="xxContent" @click="openMessage(message.id)">
                    {{ message.content }}<span>详情</span>
                  </div>
                </div>
              </div>
              <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list
                  v-model="upperLoading"
                  :finished="finished"
                  :immediate-check="false"
                  finished-text="没有更多了"
                  offset="500"
                  @load="onLoad"
                >
                  <div class="container" v-if="loading === false">
                    <div
                      class="contentBox"
                      v-for="item in lookList"
                      :key="item.id"
                    >
                      <div class="contentTop">
                        <div class="contentAbstract" @click="detailEvent(item)">
                          {{ item.title }}
                        </div>
                        <div class="contentImg">
                          <img :src="item.img" alt />
                        </div>
                      </div>
                      <div class="contentBottom">
                        <div class="contentBottomLeft">
                          <div class="zd" v-if="item.top != 0">置顶</div>
                          <div class="contentName">{{ item.createName }}</div>
                          <div class="contentTime">{{ item.timeShow }}</div>
                        </div>
                        <div class="contentLikeNum">{{ item.likeNum }}点赞</div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
            </van-tab>
          </van-tabs>
        </div>
        <div class="tabImg" @click="tabShow = true">
          <img src="../../image/tabImg.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 左侧弹框 -->
    <van-popup
      v-model="show"
      position="left"
      :style="{ height: '100%', width: '75%' }"
    >
      <div class="popBack">
        <div class="popupImg">
          <img src="../../image/lookTitle.png" alt />
        </div>
        <div class="describe">您可以在这里随时切换您感兴趣的地区和学段</div>
      </div>
      <div class="switch">切换地区</div>
      <div class="cascaderBox">
        <van-field
          v-model="fieldValue"
          is-link
          readonly
          label="省/市（县）"
          placeholder="请选择所在地区"
          @click="cascaderShow = true"
        />
      </div>
      <div class="switchPeriod">切换学段</div>
      <div class="radioBox">
        <div
          class
          v-for="(item, index) in radioList"
          :class="gradActive === item.id ? 'gradActive' : ''"
          @click="gradEvent(item, index)"
          :key="item.id"
        >
          {{ item.value }}
          <img v-if="item.status" src="../../image/blue.png" alt />
        </div>
      </div>
      <div class="popupBtn" @click="popupBtn()">确定</div>
    </van-popup>
    <!-- 频道目录 -->
    <van-popup
      v-model="tabShow"
      position="bottom"
      round
      :style="{ height: '90%' }"
    >
      <div class="delImgTabShow">
        <img @click="tabShow = false" src="../../image/delImg.png" alt />
      </div>
      <div class="tabShowTitle">
        <div>频道目录</div>
        <div>点击进入频道</div>
        <!-- <div>编辑</div> -->
      </div>
      <div class="tabShowList">
        <div
          v-for="(item, index) in tabList"
          :key="item.id"
          :class="tabShowActive === item.id ? 'tabShowactive' : ''"
          @click="tabShowEvent(item, index)"
        >
          {{ item.value }}
        </div>
      </div>
    </van-popup>
    <van-popup v-model="cascaderShow" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        active-color="#0957c3"
        @close="cascaderShow = false"
        @finish="onFinish"
        @change="onCascaderChange"
        id="size"
      />
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
import { Cascader } from "vant";
import { Tab, Tabs } from "vant";
import { Swipe, SwipeItem } from "vant";
import {
  dictionary,
  regionList,
  infoPage,
  bannerList,
  getLastOneMessage,
  messageRead,
} from "../../api/lookAt";
// import lookAtChild from "./lookAtChild";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      loading: false,
      show: false, //左侧弹框状态
      cascaderShow: false, //所在地区弹框状态
      fieldValue: "",
      cascaderValue: "",
      options: [],
      radioList: [],
      gradActive: 0,
      tabList: [],
      tabActive: "",
      tabShow: false, //频道目录弹框
      tabShowActive: "", //频道目录弹框中的列表状态
      lookData: {
        pageNum: 1,
        pageSize: 10,
        content: this.$route.query.value || "", //搜索
        provinceId: "", //省级id
        cityId: "", //市级id
        period: "", //学段id
        classify: "", //tabId
      },
      lookList: [], //列表
      bannerList: [], //轮播图
      total: 0,
      upperLoading: false, //上拉加载
      finished: true, //加载与不加载的状态
      listTwo: [], //用于上拉加载时将数据合并
      isLoading: false, //下拉加载状态
      message: {
        id: 0,
        title: "",
        content: "",
        type: "",
      },
      messageLoading: true,
    };
  },
  created() {
    console.log(this.lookData.content);
    dictionary({ parentId: 1 }).then((res) => {
      // console.log(res, "tab分类");
      this.tabList = res.data.data;
      this.$store.commit("tabList", res.data.data);
    });
    // this.gerinfoPage();
  },
  mounted() {
    this.loading = true;
    // 分类
    if (this.$store.state.tabList[0].key === "0") {
      this.lookData.classify = "";
    } else {
      this.lookData.classify = this.$store.state.tabList[0].key;
    }

    // 学段
    dictionary({ parentId: 13 }).then((res) => {
      // console.log(res, "学段");
      res.data.data.forEach((item) => {
        item.status = false;
      });
      this.radioList = res.data.data;
    });
    // 地区
    regionList().then((res) => {
      // console.log(res.data.data, "地区");
      this.options = res.data.data;
      this.$store.commit("optionsList", res.data.data);

      this.options = res.data.data.map((item) => {
        item.text = item.name;
        item.value = item.id;
        if (item.children.length > 0 || item.children != null) {
          item.children.map((i) => {
            i.text = i.name;
            i.value = i.id;
          });
        }
      });
    });
    bannerList().then((res) => {
      // console.log(res, "轮播图");
      this.bannerList = res.data.data;
    });
    this.gerinfoPage();
    this.getLastOneMessage();
  },
  methods: {
    gerinfoPage() {
      this.lookData.pageNum = 1;
      infoPage(this.lookData).then((res) => {
        console.log(res, "首次数据");
        if (res.data.code === 0) {
          this.lookList = res.data.data.items;
          this.total = res.data.data.total;
          this.loading = false;
          var num = this.total;
          this.total = num - 0;
          if (this.lookList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          } else {
            this.finished = false;
          }
        }
      });
    },
    banEvent(item) {
      // window.location.href = item.link
      window.open(item.link);
    },
    showPopup() {
      this.show = true;
    },
    onFinish({ selectedOptions }) {
      this.cascaderShow = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    // 地区change事件
    onCascaderChange(value) {
      if (value.selectedOptions.length === 2) {
        this.lookData.provinceId = value.selectedOptions[0].id;
        this.lookData.cityId = value.selectedOptions[1].id;
      }
    },
    // 切换学段
    gradEvent(item, index) {
      this.lookData.period = item.key;
      this.gradActive = item.id;
      this.radioList.forEach((item) => {
        item.status = false;
      });
      this.radioList[index].status = true;
    },
    popupBtn() {
      this.gerinfoPage();
      this.show = false;
      console.log(this.lookData);
    },
    // 导航点击事件
    tabEvent(item, index) {
      // console.log(item, index, "ppp");
      this.tabActive = item;
      this.lookData.pageNum = 1;
      this.lookList = [];
      this.$store.state.tabList.forEach((i) => {
        // console.log(i, "777");
        if (i.value == index) {
          if (i.key === "0") {
            this.lookData.classify = "";
          } else {
            this.lookData.classify = i.key;
          }
          this.tabShowActive = i.id;
          this.gerinfoPage();
        }
      });
    },
    // 导航点击事件
    tabShowEvent(item, index) {
      this.loading = true;
      // console.log(item);
      if (item.key === "0") {
        this.lookData.classify = "";
      } else {
        this.lookData.classify = item.key;
      }

      this.tabShowActive = item.id;
      this.tabActive = index;
      this.tabShow = false;
      this.gerinfoPage();
    },
    onTabChange() {
      // this.loading = true;
      // // console.log(this.tabActive);
      // this.tabList.forEach((item, index) => {
      //   if (this.tabActive === index) {
      //     console.log(item, "****");
      //     if (item.key === "0") {
      //       this.lookData.classify = "";
      //     } else {
      //       this.lookData.classify = item.key;
      //     }
      //     this.gerinfoPage();
      //   }
      // });
    },
    // 搜索
    onFocusr() {
      // this.gerinfoPage()
      this.$router.push({ path: "/lookSearch" });
    },
    // 跳转详情
    detailEvent(item) {
      this.$router.push({ path: "/lookDetail", query: { infoId: item.id } });
      this.$store.commit("item", item);
    },
    // 下拉加载
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.gerinfoPage();
      }, 1000);
    },
    // 上拉加载
    onLoad() {
      this.lookData.pageNum++; //加载时页码递增
      infoPage(this.lookData).then((res) => {
        if (res.data.code === 0) {
          this.listTwo = res.data.data.items; //将接口返回赋值data定义的数组
          this.total = res.data.data.total - 0; //将接口返回的总数据条数赋值data中定义的total
          this.listTwo.forEach((item) => {
            this.lookList.push(item);
            this.upperLoading = false;
            // this.finished = false;
          });
          // 加载状态结束 状态更新为false
          this.upperLoading = false;
          if (this.lookList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          }
        }
      });
    },
    // 查询用户最后一亲自消息状态
    getLastOneMessage() {
      if (Cookies.get("token")) {
        getLastOneMessage().then((res) => {
          console.log(res, "+++++");
          if (res.data.data === null) {
            this.messageLoading = true;
          } else {
            this.messageLoading = false;
            this.message.id = res.data.data.id;
            this.message.title = res.data.data.title;
            this.message.content = res.data.data.data;
            this.message.type = res.data.data.type;
          }
        });
      }
    },
    // 打开消息页面
    openMessage(id) {
      let data = {
        ids: [id],
      };
      messageRead(data).then((res) => {});
      this.$router.push({ path: "/messageDetail", query: { id: id } });
    },
  },
};
</script>

<style scoped>
.lookBox {
  background: #f6f8fa;
  min-height: 100vh;
}

/deep/#loading {
  text-align: center;
  margin-top: 100px;
}

.topBox {
  display: flex;
  height: 88px;
  align-items: center;
}

.topBox div:nth-child(1) {
  width: 48px;
  height: 48px;
  background-size: 100% 100%;
  margin-left: 32px;
  background-image: url("../../image/ce.png");
}

.topBox div:nth-child(2) {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 264px;
}

.lookTitle {
  width: 190px;
  height: 41px;
  background-size: 100% 100%;
  margin-left: 32px;
}

.quanImg {
  width: 98px;
  height: 44px;
  margin-right: 32px;
}

.searchBox {
  display: flex;
  align-items: center;
}

.leftBox {
  width: 48px;
  height: 48px;
  background-size: 100% 100%;
  margin-left: 32px;
  background-image: url("../../image/ce.png");
  background-size: 100% 100%;
}

.searchDiv {
  width: 686px;
  height: 72px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
}

.searchDiv img {
  width: 28px;
  height: 28px;
  margin-left: 16px;
}

.searchDiv input {
  width: 440px;
  height: 40px;
  outline: none;
  border: none;
  font-size: 28px;
  margin-left: 16px;
  display: flex;
}

.seaDel {
  margin-left: 32px !important;
}

/deep/.van-cell {
  padding: 0px;
  background-color: transparent;
}

.van-popup--left {
  width: 560px;
  height: 1624px;
  background-image: url("../../image/ceback.png");
}

/* .popBack {
  width: 564px;
  height: 296px;
  background: linear-gradient(180deg, #b2c7ff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(
      178deg,
      rgba(87, 191, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 99%
    );
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
} */
.popupImg img {
  width: 190px;
  height: 41px;
  margin-left: 48px;
  margin-top: 102px;
}

.describe {
  width: 464px;
  height: 88px;
  font-size: 28px;
  font-weight: 400;
  color: #455669;
  margin-left: 48px;
  margin-top: 32px;
  line-height: 40px;
}

.cascaderBox {
  width: 464px;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 48px;

  border-bottom: 2px solid #ebedf0;
}

.switch {
  font-size: 32px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 48px;
  margin-top: 122px;
}

.switchPeriod {
  font-size: 32px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 44px;
  margin-left: 48px;
}

.radioBox {
  width: 464px;
  margin-left: 48px;
  margin-top: 48px;
}

.radioBox div {
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 96px;
}

.radioBox img {
  width: 32px;
  height: 32px;
}

.gradActive {
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
}

.popupBtn {
  width: 464px;
  height: 90px;
  background: #0957c3;
  border-radius: 16px;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  margin: 0 auto;
  margin-top: 100px;
}

.tabBox {
  position: relative;
}

.tabImg {
  width: 52px;
  height: 60px;
  position: absolute;
  top: 28px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabImg img {
  width: 96px;
  height: 60px;
}

/deep/.van-tabs__nav {
  background-color: transparent;
}

/deep/.van-tab--active {
  /* width: 104px; */
  /* height: 58px; */
  /* line-height: 58px; */
  color: #0957c3 !important;
  background: rgba(9, 87, 195, 0.15) !important;
  font-size: 28px;
  font-weight: 600;
  border-radius: 8px;
}

/deep/.van-tab {
  /* width: 104px; */
  /* height: 58px; */
  /* line-height: 58px; */
  color: #0b1526;
  background: rgba(177, 180, 195, 0.2);
  margin-left: 20px;
  margin-top: 32px;
  border-radius: 8px;
}

/deep/.van-tabs__line {
  background-color: transparent;
}

/deep/.van-tabs--line .van-tabs__wrap {
  width: 686px;
}

.delImgTabShow img {
  width: 32px;
  height: 32px;
  margin-left: 32px;
}

.tabShowTitle {
  display: flex;
  align-items: center;
}

.tabShowTitle div:nth-child(1) {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  margin-left: 32px;
}

.tabShowTitle div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 32px;
}

.tabShowTitle div:nth-child(3) {
  font-size: 28px;
  font-weight: 500;
  color: #5777ed;
  margin-left: 326px;
}

.tabShowList {
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 48px;
  margin-left: 6px;
}

.tabShowList div {
  width: 152px;
  height: 74px;
  background: #f7f8fa;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-bottom: 26px;
  text-align: center;
  line-height: 74px;
  margin-left: 26px;
}

.tabShowactive {
  color: #5777ed;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  height: 320px;
  border-radius: 8px 8px 8px 8px;
}

.bannerBox {
  width: 686px;
  height: 320px;
  margin-left: 32px;
  margin-top: 32px;
  position: relative;
}

.bannerImg {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.bannerTitle {
  width: 686px;
  height: 72px;
  background: rgba(11, 21, 38, 0.5);
  border-radius: 0px 0px 16px 16px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bannerTitle div {
  margin-left: 32px;
}

.bannerTitle img {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}

/* /deep/.van-swipe__indicators {
} */
.zd {
  width: 64px;
  height: 44px;
  background: rgba(69, 103, 230, 0.1);
  font-size: 24px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  color: #0957c3;
  line-height: 47px;
  text-align: center;
  margin-right: 16px;
  border-radius: 8px;
}

.contentName,
.contentTime {
  margin-left: 16px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
}

.contentName {
  margin-left: 0px;
}

.likeNum {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.26);
  text-align: right;
  margin-top: 24px;
}

.noticeBox {
  width: 686px;
  height: 122px;
  margin-left: 32px;
  margin-top: 32px;
  display: flex;
  margin-bottom: 26px;
}

.noticeRight {
  width: 120px;
  height: 122px;
  background: #fff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noticeRight img {
  width: 88px;
  height: 90px;
}

.noticeLeft {
  width: 562px;
  height: 122px;
  background: #fff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: 2px;
}

.xxTitle {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-top: 22px;
  margin-left: 18px;
}

.xxContent {
  /* width: 384px; */
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 8px;
  margin-left: 18px;
  /* display: flex; */
  /* align-items: center; */
}

.xxContent span {
  font-size: 24px;
  font-weight: 400;
  color: #0957c3;
}

.container {
  width: 686px !important;
  background: #ffffff;
  /* margin-left: 32px; */
  border-radius: 16px !important;
  /* margin-top: 26px; */
  /* padding-bottom: 180px; */
}

.contentBox {
  width: 686px;
  height: 260px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  overflow: hidden;
  /* border-radius: 16px !important; */
  /* margin-left:32px; */
  /* margin-top: 32px !important;  */
}

.van-list {
  width: 686px;
  margin-left: 32px;
}

.contentTop {
  display: flex;
  margin-top: 32px;
}

.contentAbstract {
  width: 380px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  height: 146px;
  margin-left: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.contentImg img {
  width: 210px;
  height: 140px;
  border-radius: 8px 8px 8px 8px;
  margin-left: 32px;
}

.contentBottom {
  height: 36px;
  display: flex;
  justify-content: space-between;
}

.contentBottomLeft {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.contentLikeNum {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-right: 32px;
}
</style>
